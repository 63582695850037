import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

// reducers
import interfaceInteractionReducer from './interfaceInteractionReducer';
import userReducer from './userReducer';
import dashboardReducer from './dashboardReducer';
import landingReducer from './landingReducer';

export default combineReducers({
  form: reduxFormReducer,
  uiInteractions: interfaceInteractionReducer,
  user: userReducer,
  appData: dashboardReducer,
  landing: landingReducer,
});
