import { actionTypes } from 'ACTIONS';

const initialState = {
  loading: false,
  contentLoading: true,
  modals: {
    isAnyModalOpen: false,
  },
  dropdowns: {},
  customMessages: {},
  isNotificationSeen: {
    isSeen: true,
  },
  isSidebarOpen: true,
  isBurgerNavOpen: false,
  subscribers: {},
  cookieData: null,
  editableForms: {},
  pageWithSwitcher: {},
  customLoaders: {},
};

// counterReducer :: (object, object) -> object
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADING:
      return { ...state, loading: action.isLoading };
    case actionTypes.CONTENT_LOADING:
      return { ...state, contentLoading: action.isLoading };
    case actionTypes.TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          isAnyModalOpen: action.payload.isOpen,
          [action.payload.modalId]: {
            isOpen: action.payload.isOpen,
          },
        },
      };
    case actionTypes.TOGGLE_DROPDOWN:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          [action.payload.id]: {
            isOpen: action.payload.isOpen,
          },
        },
      };
    case actionTypes.CALL_CUSTOM_MESSAGE:
      return {
        ...state,
        customMessages: {
          ...state.customMessages,
          [action.payload.id]: {
            messageType: action.payload.messageType,
            isMessage: action.payload.isMessage,
            message: action.payload.message,
          },
        },
      };
    case actionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        customMessages: {
          [action.id]: {
            isData: false,
          },
        },
      };
    case actionTypes.TOGGLE_NOTIFICATION_SEEN:
      return {
        ...state,
        isNotificationSeen: action.isSeen,
      };
    case actionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    case actionTypes.TOGGLE_BURGER:
      return {
        ...state,
        isBurgerNavOpen: action.isOpen,
      };
    case actionTypes.SET_SUBSCRIBERS:
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          [action.payload.id]: action.payload.subscriber,
        },
      };

    case actionTypes.SET_COOKIE_DATA:
      return {
        ...state,
        cookieData: action.data,
      };

    case actionTypes.TOGGLE_EDIT_FORM:
      return {
        ...state,
        editableForms: {
          ...state.editableForms,
          [action.payload.formId]: {
            isEditable: action.payload.isEditable,
          },
        },
      };

    case actionTypes.SWITCH_PAGE:
      return {
        ...state,
        pageWithSwitcher: {
          [action.payload.page]: {
            activePage: action.payload.activePageId,
          },
        },
      };

    case actionTypes.TOGGLE_CUSTOM_LOADER:
      return {
        ...state,
        customLoaders: {
          ...state.customLoaders,
          [action.payload.id]: action.payload.isLoading,
        },
      };

    default: return state;
  }
};
