import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import XHR from 'i18next-xhr-backend';

import { checkAndSetUserLanguage } from 'FUNCTIONS';
import translations from './translations';

const i18n = i18next
  .use(XHR)
  .use(reactI18nextModule)
  .init({
    backend: {
      loadPath: '{{lng}}',
      allowMultiLoading: true,
      parse: data => data,
      ajax: loadLocales,
    },
    lng: checkAndSetUserLanguage(),
    fallbackLng: checkAndSetUserLanguage(),
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

function loadLocales(url, options, callback) {
  callback(translations[checkAndSetUserLanguage()], { status: '200' });
  i18n.addResourceBundle(checkAndSetUserLanguage(), 'translation');
}


export default i18n;
