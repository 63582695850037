import App, { Container } from 'next/app';
import React from 'react';
import withReduxStore from 'HOCS/with-redux-store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';

// other
import env from 'CONFIG/env';
import i18n from './i18n';


// comps
// import CookieNotification from 'COMPONENTS/CookieNotification';


const { pixelId } = env;
const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};

class MyApp extends App {
  componentDidMount() {
    ReactPixel.init(pixelId, advancedMatching, options);
    ReactPixel.pageView();
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    return (
      <Container>
        <I18nextProvider i18n={i18n}>
          <Provider store={reduxStore}>
            <>
              <Component {...pageProps} />
              {/*<CookieNotification />*/}
            </>
          </Provider>
        </I18nextProvider>
      </Container>
    );
  }
}

export default withReduxStore(MyApp);
