import { actionTypes } from 'ACTIONS';

const initialState = {
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

// counterReducer :: (object, object) -> object
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUCCESS:
      return { ...state, isSuccess: true };
    case actionTypes.ERROR:
      return { ...state, isError: true, errorMessage: action.errorMessage };
    default: return state;
  }
};
