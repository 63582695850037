import { actionTypes } from 'ACTIONS';

const initialState = {
  dashboardData: {
    isData: false,
  },
  layoutData: {
    isData: false,
  },
  orders: {
    isData: false,
    data: [],
  },
  upgradePrices: {},
};

// counterReducer :: (object, object) -> object
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          ...action.data,
        },
      };

    case actionTypes.GET_DASHBOARD_LAYOUT_DATA:
      return {
        ...state,
        layoutData: {
          ...state.layoutData,
          ...action.data,
        },
      };

    case actionTypes.GET_UPGRADE_PRICES:
      return {
        ...state,
        upgradePrices: action.data,
      };

    case actionTypes.GET_ORDERS:
      return {
        ...state,
        orders: {
          isData: action.payload.isData,
          data: [...state.orders.data, ...action.payload.orders],
          pagesQuantity: action.payload.pagesQuantity,
          isMoreThenOnePage: action.payload.isMoreThenOnePage,
        },
      };

    default: return state;
  }
};
