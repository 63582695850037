import { actionTypes } from 'ACTIONS';

const initialStore = {
  homepageData: null,
  footerData: null,
  defaultLayoutData: null,
  contactPageData: null,
  recaptchaResponse: null,
  headerData: {
    isAuthorized: false,
    isLoading: true,
  },
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case actionTypes.SET_HOMEPAGE_DATA:
      return {
        ...state,
        homepageData: {
          ...state.homepageData,
          ...action.data,
        },
      };

    case actionTypes.SET_LANDING_FOOTER_DATA:
      return {
        ...state,
        footerData: {
          ...state.footerData,
          ...action.data,
        },
      };

    case actionTypes.SET_LANDING_HEADER_DATA:
      return {
        ...state,
        headerData: {
          ...state.headerData,
          ...action.data,
        },
      };

    case actionTypes.SET_DEFAULT_LAYOUT_DATA:
      return {
        ...state,
        defaultLayoutData: {
          ...state.defaultLayoutData,
          ...action.data,
        },
      };

    case actionTypes.SET_CONTACT_PAGE_DATA:
      return {
        ...state,
        contactPageData: {
          ...state.contactPageData,
          ...action.data,
        },
      };

    case actionTypes.SET_RECAPTCHA:
      return {
        ...state,
        recaptchaResponse: action.response,
      };

    case actionTypes.REMOVE_RECAPTCHA:
      return {
        ...state,
        recaptchaResponse: null,
      };

    default: return state;
  }
};
